import { FC, PropsWithChildren } from 'react';
import ThemeSwitcher from '@shared/ThemeSwitcher';

interface Props {
  className?: string;
}

const MainContainer: FC<PropsWithChildren<Props>> = ({ children }) => {
  return (
    <div className="bg-primary min-h-screen flex flex-row justify-center" style={{ paddingBottom: '120px', position: 'relative' }}>
      <div
        className="flex"
        style={{
          alignItems: 'start',
          position: 'absolute',
          top: '10px',
          left: '10px',
        }}
      >
        <div
          className="mt-3 ml-4"
          style={{
            backgroundImage: 'var(--app-logo)',
            width: '150px',
            height: '150px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
      </div>

      <div className="container p-8 max-w-2xl mx-auto rounded-xl space-y-6 items-center" >{children}</div>
      <ThemeSwitcher />
    </div>
  );
};

export default MainContainer;
